import { useState } from "react";
import styles from "./menuleft.module.css";
import iconmenu from "../../images/menu.png";
import iconmenux from "../../images/xmenu.png";
import { listMenu } from "../../utils/data";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

function MenuLeft() {
  let index = 1;
  const [open, setopen] = useState(false);

  return (
    <div className={styles.main}>
      {!open && (
        <div
          className={styles.icon}
          onClick={() => {
            setopen(true);
          }}
        >
          <img src={iconmenu} alt="menu"></img>
        </div>
      )}
      {open && (
        <div
          className={styles.icon}
          onClick={() => {
            setopen(false);
          }}
        >
          <img src={iconmenux} alt="menuclouse"></img>
        </div>
      )}

      {open && (
        <nav className={styles.menu}>
          <ul className={styles.menulist}>
            {listMenu.map((item) => {
              index = index + 1;
              return (
                <motion.div whileHover={{ scale: 1.2 }} key={index}>
                  <NavLink
                    className={styles.menuitem}
                    to={item.link}
                    key={index}
                    onClick={() => {
                      setopen(!open);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </motion.div>
              );
            })}
          </ul>
          <motion.div whileHover={{ scale: 1.1 }} key={index}>
            <NavLink
              className={styles.menubutton}
              to="/order"
              key={index}
              onClick={() => {
                setopen(!open);
              }}
            >
              Заказать
            </NavLink>
          </motion.div>
        </nav>
      )}
    </div>
  );
}

export default MenuLeft;
