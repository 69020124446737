// @ts-ignore: error message
import { v4 as uuid } from "uuid";
import image05 from "../images/image005.png";
import image08 from "../images/image008.png";
import image015 from "../images/image015.png";
import price from "../images/price.png";
import pricew from "../images/pricew.png";
import limpo from "../images/limpo.png";
import limpow from "../images/limpow.png";
import clock from "../images/clock.png";
import clockw from "../images/clockw.png";
import ok from "../images/ok.png";
import okw from "../images/okw.png";
import troom from "../images/toilet.png";
import kroom from "../images/kitchen.png";
import tel from "../images/tel.png";
import telw from "../images/telactive.png";
import wa from "../images/wa.png";
import waw from "../images/waactive.png";
import tg from "../images/telegram.png";
import tgw from "../images/telegramactive.png";
import call from "../images/call.png";
import callw from "../images/callw.png";
import { months } from "moment";

export const textr = [
  "Скидка 20% на пробную уборку",
  "-30% на Генеральную уборку кухни",
  "-30% на Генеральную уборку ванной",
];

export const baseUrl =
  "https:/api.telegram.org/bot7402326848:AAENKvYfBNA7sHIx7F2RaTD8Cg6EQu4AYb0/";

export const workdata = [
  {
    day: 25,
    month: 6,
  },
  {
    day: 26,
    month: 6,
  },
  {
    day: 16,
    month: 6,
  },
  {
    day: 16,
    month: 7,
  },
  {
    day: 24,
    month: 8,
  },
];

export const patreon =
  "https://patreon.com/portuguesdicionario?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink";

export const dataCall = [
  {
    name: "Позвонить по телефону: + (351) 911 927 602 ",
    actionType: "мы доступны с понедельника по воскресенье с 9 до 18 часов",
    icon: tel,
    iconw: telw,
    link: "",
  },
  {
    name: "Написать или позвонить в WhatsApp",
    actionType: "отвечаем на сообщения 30 течении мин.",
    icon: wa,
    iconw: waw,
    link: "https://wa.me/351911927602?text=Здравствуйте.%20Хочу%20заказать%20уборку%20перезвоните мне.",
  },
  {
    name: "Написать или позвонить в Telegram",
    actionType: "отвечаем на сообщения 30 течении мин.",
    icon: tg,
    iconw: tgw,
    link: "https://t.me/Russiancleaninginporto",
  },

  {
    name: "Заказать звонок на сайте, можно тут",
    actionType: "Перезваниваем в течении 30 мин. ежедневно с 9 до 22 часов",
    icon: call,
    iconw: callw,
    link: "",
  },
];

export const dataCompany = [
  {
    name: "Фиксированные и понятные цены",
    actionType: "Вы сразу видите окончательную стоимость уборки.",
    icon: price,
    iconw: pricew,
  },
  {
    name: "Хорошая либо бесплатная уборка",
    actionType: "Если вас не устроит качество уборки, мы сделаем ее бесплатно",
    icon: limpo,
    iconw: limpow,
  },

  {
    name: "Пунктуальность и ответственность",
    actionType: "Мы всегда прибываем вовремя и ответственно относимся к работе",
    icon: clock,
    iconw: clockw,
  },
  {
    name: "Мы говорим по русски",
    actionType: "Нам не нужно искать общий язык, чтобы договориться",
    icon: ok,
    iconw: okw,
  },
];

export const listtext = [
  "Провести время с семьей",
  "Встретиться с друзьями",
  "Погулять с собакой на океане",
  "Заняться шоппингом",
  "Сходить в кино",
  "Посмотреть любимый сериал",
  "Заняться фитнесом в зале",
  "Просто выспаться",
];

export type Typeloc = {
  name: string;
  ref: string;
  work: string[];
};

export const pricebase = 20;
export const pricebasewc = 20;
export const pricebaseroom = 10;
export const timebasewc = 2;
export const timebaseroom = 0.6;
export const timebase = 1.2;

export const listMenu = [
  { name: "Главная", link: "/" },
  { name: "Услуги", link: "/clean" },
  { name: "Цены", link: "/price" },
  { name: "О нас", link: "/company" },
  { name: "Интересное", link: "/advice" },
  { name: "Работа", link: "/work" },
];
export type Tflat = { flat: string; name: string; kof: number; time: number };

export const flats = [
  { flat: "Однокомнатная", name: "Т0, Т1", kof: 1, time: 3 },
  { flat: "Двухкомнатная", name: "Т2", kof: 1.2, time: 4 },
  { flat: "Трехкомнатная", name: "Т3", kof: 1.3, time: 5 },
];
export type TlistLoc = {
  name: string;
  ref: string;
  work: string[];
};

export const listLoc = [
  {
    name: "В комнате",
    ref: "Room",
    work: [
      "Пылесосим и моем пол.",
      "Чистим диваны и ковры сухим пылесосом.",
      "Моем легкодоступные окна изнутри.",
      "Протираем зеркала",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
      "Протираем подоконники, плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
      "Меняем постельное белье (при предоставлении сменного комплекта)",
    ],
  },
  {
    name: "На кухне",
    ref: "Kitchen",
    work: [
      "Пылесосим и моем пол.",
      "Моем легкодоступные окна изнутри.",
      "Моем плиту, раковину и столешницу",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов,при сильном загрязнении требуется генеральная уборка",
      "Моем подоконники, плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
      "Моем посуду. Небольшое количество в раковине",
    ],
  },
  {
    name: "В коридоре",
    ref: "Coredor",
    work: [
      "Пылесосим и моем пол.",
      "Протираем зеркала",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
      "Моем плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
      "Аккуратно сложим и развесим одежду и расставим обувь",
    ],
  },
  {
    name: "В санузле",
    ref: "wc",
    work: [
      "Моем пол и плинтусы",
      "Моем и дезинфицируем раковину и унитаз",
      "Моем легкодоступные окна изнутри.",
      "Протираем зеркала",
      "Протираем плитку, ванну, душевую кабину, при сильном загрязнении требуется генеральная уборка",
      "Моем подоконники, плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
    ],
  },
];

export const listLocG = [
  {
    name: "В комнате",
    ref: "Room",
    work: [
      "Пылесосим и моем пол.",
      "Чистим диваны и ковры сухим пылесосом.",
      "Моем легкодоступные окна изнутри, протираем зеркала",
      "Протираем моющиеся поверхности стен и потолков, либо производим чистку пылесосом",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
      "Моем внутри шкафы и гардеробные (должны быть освобождены)",
      "Моем подоконники, плинтусы, ручки, двери, выключатели.",
      "Меняем постельное белье (при предоставлении сменного комплекта)",
    ],
  },
  {
    name: "На кухне",
    ref: "Kitchen",
    work: [
      "Пылесосим и моем пол.",
      "Моем легкодоступные окна изнутри.",
      "Моем плиту, раковину и столешницу",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов",
      "Чистим бытовую технику и мебель внутри (должны быть освобождены)",
      "Моем подоконники, плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
      "Моем посуду. Небольшое количество в раковине",
    ],
  },
  {
    name: "В коридоре",
    ref: "Coredor",
    work: [
      "Пылесосим и моем пол.",
      "Протираем зеркала",
      "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
      "Протираем моющиеся поверхности стен и потолков, либо производим чистку пылесосом",
      "Моем внутри шкафы и гардеробные (должны быть освобождены)",
      "Моем плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
      "Аккуратно сложим и развесим одежду и расставим обувь",
    ],
  },
  {
    name: "В санузле",
    ref: "wc",
    work: [
      "Моем пол и плинтусы",
      "Моем и дезинфицируем раковину и унитаз",
      "Моем легкодоступные окна изнутри.",
      "Протираем зеркала",
      "Моем и тщательно дезинфицируем плитку, ванну, душевую кабину",
      "Моем подоконники, плинтусы, ручки, двери, выключатели.",
      "Убираем и выносим мусор",
    ],
  },
];

export const listLocR = [
  {
    name: "В комнате",
    ref: "Room",
    work: [
      "Очищаем от пыли все поверхности и стены",
      "Моем пол, подоконники, батареи, двери, плинтусы",
      "Удаляем следы и пятна от строительных смесей",
      "Собираем и выносим мусор",
      "Моем окна и рамы",
    ],
  },
  {
    name: "На кухне",
    ref: "Kitchen",
    work: [
      "Очищаем от пыли все поверхности и стены",
      "Моем пол, подоконники, плинтусы, двери, батареи, шкафы, технику",
      "Удаляем следы и пятна от строительных смесей",
      "Собираем и выносим мусор",
      "Моем фасады кухни",
      "Убираем внутри кухонных шкафов",
    ],
  },
  {
    name: "В коридоре",
    ref: "Coredor",
    work: [
      "Очищаем от пыли все поверхности и стены",
      "Моем пол, кафельную плитку, двери, батареи, чистим зеркала",
      "Удаляем пятна от строительных смесей",
      "Собираем и выносим мусор",
      "Моем мебель внутри и снаружи",
    ],
  },
  {
    name: "В санузле",
    ref: "wc",
    work: [
      "Очищаем от пыли все поверхности и стены",
      "Моем пол, кафельную плитку, двери, батареи, ванную и унитаз, чистим зеркала",
      "Удаляем пятна от строительных смесей",
      "Собираем и выносим мусор",
      "Моем мебель внутри и снаружи",
    ],
  },
];
export const commentallone = [
  "Закажите один раз, без каких-либо обязательств по будущим услугам.",
  "Идеально подходит для первого заказа, оцените наши стандарты чистоты.",
  "Фундаментальная, качественная уборка, подходящая для повседневных нужд.",
  "Для каждой уборки используем новые губки, спонжи, тряпочки",
  "Все работы мы выполняем тщательно и аккуратно, учитываем пожелания заказчиков.",
];
export const commentalltwo = [
  "Постоянная чистота с плановыми регулярными посещениями.",
  "Отмените, пропустите или перенесите отдельные сеансы уборки с уведомлением за 24 часа.",
  "Наслаждайтесь комфортом использования одного и того же надежного человека каждый раз.",
  "Лучшие цены на постоянную и надежную уборку дома.",
  "Для каждой уборки используем новые губки, спонжи, тряпочки",
  "Все работы мы выполняем тщательно и аккуратно, учитываем пожелания заказчиков.",
];

export const commentallthree = [
  "Тщательная уборка, достигает каждого уголка.",
  "Особое внимание уделяется удалению аллергенов и пыли для создания более здоровой окружающей среды.",
  "Используем средства, позволяющие не только очищать загрязнения, но и сохранять поверхность мебели и полов в превосходном виде.",
  "Для каждой уборки используем новые губки, спонжи, тряпочки",
  "Все работы мы выполняем тщательно и аккуратно, учитываем пожелания заказчиков.",
];
export const commentallfour = [
  "Тщательная уборка, достигает каждого уголка.",
  "Особое внимание уделяется удалению пыли для создания более здоровой окружающей среды.",
  "Используем средства, позволяющие не только очищать загрязнения, но и сохранять поверхность мебели и полов в превосходном виде.",
  "Все работы мы выполняем тщательно и аккуратно, учитываем пожелания заказчиков.",
];

export const commentBw = [
  "Пылесосим и моем пол.",
  "Аккуратно сложим и развесим одежду и расставим обувь",
  "Чистим диваны и ковры сухим пылесосом.",
  "Моем легкодоступные окна изнутри.",
  "Протираем зеркала",
  "Моем плиту, раковину и столешницу",
  "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
  "Протираем подоконники, плинтусы, ручки, двери, выключатели.",
  "Протираем плитку, ванну, душевую кабину, при сильном загрязнении требуется генеральная уборка",
  "Моем и дезинфицируем раковину и унитаз",
  "Убираем и выносим мусор",
  "Меняем постельное белье (при предоставлении сменного комплекта)",
];
export const commentBwn = [
  "Внутренняя чистка шкафов, гардеробов и бытовой техники",
  "Уборка мест для домашних животных",
  "Очистка от известкового налета, жира, плесени и въевшейся грязи",
  "Очистка рам, стен и потолков",
  "Уборка террас, дворов, садов или балконов",
  "Мытье больших окон или мойка наружных стекол",
  "Очистка от влаги и плесени",
  "Уборка после строительства",
];

export const commentGw = [
  "Пылесосим и моем пол.",
  "Аккуратно сложим и развесим одежду и расставим обувь",
  "Чистим диваны и ковры сухим пылесосом.",
  "Моем легкодоступные окна изнутри.",
  "Протираем зеркала",
  "Моем плиту, раковину и столешницу",
  "Удаляем пыль и видимые загрязнения с мебели, бытовой техники и открытых предметов.",
  "Протираем подоконники, плинтусы, ручки, двери, выключатели.",
  "Протираем плитку, ванну, душевую кабину",
  "Очистка от известкового налета, жира и въевшейся грязи. Некоторые пятна такого рода полностью удалить очень сложно или почти невозможно.",
  "Моем и дезинфицируем раковину и унитаз",
  "Убираем и выносим мусор",
  "Меняем постельное белье (при предоставлении сменного комплекта)",
];
export const commentGwn = [
  "Уборка мест для домашних животных",
  "Уборка террас, дворов, садов или балконов",
  "Мытье больших окон или мойка наружных стекол",
  "Очистка от влаги и плесени",
  "Уборка после строительства",
];
export const commentRw = [
  "Пылесосим и моем пол.",
  "Моем легкодоступные окна изнутри.",
  "Протираем зеркала и стекляные поверхности",
  "Моем плиту, раковину и столешницу",
  "Моем мебель внутри и снаружи",
  "Протираем подоконники, плинтусы, ручки, двери, выключатели.",
  "Моем и дезинфицируем раковину и унитаз, плитку, ванну, душевую кабину",
  "Убираем и выносим мусор",
  "Удаляем цементную или другую строительную пыли сухим или влажным способом",
  "Удаляем следы клея с оконных рам и подоконников, дверей, откосов и т.д. Важно: нельзя надолго оставлять защитные пленки и скотч на пластиковых поверхностях и оконных рамах. При длительном контакте с поверхностью клей может необратимо повлиять на ее структуру и цвет.",
  "Удаляем следы цемента, затирки, краски. Некоторые пятна такого рода полностью удалить очень сложно или почти невозможно. Цементные загрязнения подлежат немедленному удалению.",
];
export const commentRwn = [
  "Уборка мест для домашних животных",
  "Очистка от известкового налета, жира, плесени и въевшейся грязи",
  "Уборка террас, дворов, садов или балконов",
  "Мытье больших окон или мойка наружных стекол",
  "Очистка от влаги и плесени",
];

export type TtypeClean = {
  name: string;
  kof: number;

  com: string[];
  koftime: number;
  comw: string[];
  comwn: string[];
};

export const typeCleanbase = {
  name: "Разовая базовая уборка",
  kof: 1.0,

  com: commentallone,
  koftime: 1,
  comw: commentBw,
  comwn: commentBwn,
};

export const typeClean = [
  {
    name: "Базовая уборка (раз в неделю)",
    kof: 0.8,
    com: commentalltwo,
    koftime: 1,
    comw: commentBw,
    comwn: commentBwn,
  },
  {
    name: "Базовая уборка (раз в 2 недели)",
    kof: 0.9,
    com: commentalltwo,
    koftime: 1,
    comw: commentBw,
    comwn: commentBwn,
  },
  {
    name: "Разовая базовая уборка",
    kof: 1.0,
    com: commentallone,
    koftime: 1,
    comw: commentBw,
    comwn: commentBwn,
  },
  {
    name: "Разовая генеральная уборка",
    kof: 1.3,
    com: commentallthree,
    koftime: 1.5,
    comw: commentGw,
    comwn: commentGwn,
  },
  {
    name: "Уборка после ремонта",
    kof: 1.3,
    com: commentallfour,
    koftime: 1.5,
    comw: commentRw,
    comwn: commentRwn,
  },
];

export type Tlistdop = {
  name: string;
  list: string[];
  image: string;
  price: number;
};

export const listdopbase = {
  name: "Оборудование и средства для уборки",
  list: ["Набор новых спонжей и тряпок"],
  image: image05,
  price: 0,
};
export const listdop = [
  {
    name: "Стирка и глажка белья",
    list: [
      "Стирка и глажка постельного белья и полотенец",
      "Забор и доставка белья",
      "Услуга выполняется в течение 2-3 рабочих дней",
    ],
    image: image05,
    price: 1.5,
  },
  {
    name: "Мойка стекол и окон",
    list: [
      "Мойка окон, жалюзи, внутренних и наружных рам (за исключением труднодоступных)",
      "Услуга с почасовой оплатой",
    ],
    image: image015,
    price: 20,
  },
  {
    name: "Генеральная уборка ванной комнаты",
    list: [
      "Тщательно моем и дезинфицируем всю плитку и оборудование",
      "Продолжительность 3-4 часа",
    ],
    image: troom,
    price: 40,
  },
  {
    name: "Генеральная уборка кухни",
    list: [
      "Уборка всего, что есть в кухне",
      "Вся техника и мебель моется внутри и снаружи.",
      "Продолжительность 5-6 часов",
    ],
    image: kroom,
    price: 50,
  },
  {
    name: "Глажка белья",
    list: [
      "Только глажка постельного белья и полотенец",
      "Забор и доставка белья",
      "Услуга выполняется в течение 2-3 рабочих дней",
    ],
    image: image05,
    price: 1.0,
  },
];

export type Tlistworkdop = {
  name: string;
  price: number;
};

export const listworkdop = [
  {
    name: "Мойка вытяжки",
    price: 10,
  },
  {
    name: "Мойка духовки",
    price: 20,
  },
  {
    name: "Мойка микроволновки",
    price: 10,
  },
  {
    name: "Мойка окна",
    price: 15,
  },
  {
    name: "Мойка холодильника",
    price: 12,
  },
  {
    name: "Мойка балкона",
    price: 20,
  },
  {
    name: "Уборка кухонного шкафа",
    price: 5,
  },
  {
    name: "Уборка шкафа",
    price: 8,
  },
  {
    name: "Мойка посудомойки",
    price: 10,
  },
  {
    name: "Уборка лотка животных",
    price: 15,
  },
];
