import styles from "./adviceone.module.css";

import iconfon from "../../images/iconfon.jpg";

import { texta } from "../../utils/text";
import { useParams } from "react-router-dom";

const Adviceone = () => {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };
  const param = Number(useParams().name);
  const text = texta[param];

  return (
    <div className={styles.main} style={backgraundMain}>
      <h1 className={styles.text}>{text.name}</h1>
      <div className={styles.typeClining}>
        {text.content.map((item, index) => {
          return (
            <div className={styles.item} key={index}>
              <h2 className={styles.texth}>{item.name}</h2>
              <p className={styles.textp}>{item.text}</p>
              <img className={styles.image} src={item.image} alt="pictura" />
            </div>
          );
        })}
      </div>
      <p className={`${styles.text} ${styles.texh}`}>
        Белый шпиц желает Вам здоровья!
      </p>
    </div>
  );
};

export default Adviceone;
//useParams
