import t1_1 from "../images/text1_1.jpg";
import t1_2 from "../images/text1_2.jpeg";
import t1_3 from "../images/text1_3.jpg";
import logo from "../images/logodog.jpg";
import t11_1 from "../images/text1_1.png";
import t11_2 from "../images/text1_2.png";
import t11_3 from "../images/text1_3.png";
import t11_4 from "../images/text1_4.png";
export const texta = [
  {
    name: "Три простых шага к здоровью",

    content: [
      {
        name: "Компьютерная клавиатура может быть в пять раз грязнее сиденья унитаза.",
        text: " Часто можно увидеть, что люди хватаются за еду, используя клавиатуру, но не заботятся о том, чтобы как следует ее очистить. Будь то вечерние напитки с кофеином или закуски, вы всегда можете найти то или иное в качестве компаньона, печатая на клавиатуре компьютера. Во время работы он служит топливом для великих мыслей и идей. Но если вы не понимаете, что ваша клавиатура на самом деле не в рабочем состоянии, возможно, вскоре вам придется купить новую. Хотели бы вы работать на клавиатуре, покрытой жиром, крошками или пятнами, покрытой бактериями? Если нет, постарайтесь регулярно чистить клавиатуру. Это может увеличить срок службы вашей клавиатуры.",
        image: t1_1,
      },
      {
        name: "Избавьтесь от пыли и крошек",
        text: "Перед процессом дезинфекции микробов важно избавиться от крошек и частиц пыли, оставшихся на клавиатуре вашего компьютера. Как только вы выключите компьютер и отсоедините его от сети, переверните клавиатуру и встряхните ее. Вы также можете использовать баллон со сжатым воздухом или фен, чтобы сдуть с клавиатуры остатки царапин.",
        image: t1_2,
      },
      {
        name: "Дезинфекция клавиш",
        text: "Как только все частицы исчезнут с клавиатуры, используйте дезинфицирующую салфетку и оберните ею конец ложки. Теперь проведите дезинфицирующей салфеткой по поверхности и краям клавиатуры. Это поможет очистить грязь и пыль, спрятанную по краям клавиатуры. Особое внимание следует уделить наиболее часто используемым областям, таким как пробел.",
        image: t1_3,
      },
      {
        name: "Заключение",
        text: "Простуда и кашель — распространенные проблемы, которые также могут привести к появлению бактерий и микробов. Не рискуйте своим здоровьем, ведь от таких инфекций можно защититься, просто уделив немного времени чистке клавиатуры. Это так же важно, как и уборка ванной комнаты. ",
        image: logo,
      },
    ],
  },
  {
    name: "Советы по уборке, чтобы избежать симптомов аллергии",
    content: [
      {
        name: "Сведение к минимуму воздействия аллергенов, если вы страдаете аллергией, улучшит качество вашей жизни.",
        text: "Регулярная уборка дома требует некоторого времени и усилий, и если вы или члены вашей семьи страдаете астмой или симптомами аллергии, вам необходимо помнить о нескольких дополнительных проблемах. Однако с очисткой от аллергии справиться не так уж и сложно. Важно помнить, что полностью избавиться от аллергенов просто невозможно, но вы все равно можете успешно минимизировать их влияние на свое здоровье, а поддержание их на низком уровне будет творить чудеса.",
        image: t11_1,
      },
      {
        name: "Регулярно пылесосьте и мойте пол",
        text: "Пылесосить весь дом один или два раза в неделю — это разумная частота, и она будет иметь огромное значение! Мытье пола после этого также является необходимым шагом. Если вы страдаете аллергией, подумайте о том, чтобы избавиться от ковров, поскольку они подобны магнитам, притягивающим пылевых клещей, и поддерживать их в безупречной чистоте практически невозможно. Убедитесь, что ваш пылесос оснащен HEPA-фильтром, чтобы вся пыль оставалась внутри.",
        image: t11_2,
      },
      {
        name: "Часто стирайте простыни",
        text: "Уборка при аллергии предполагает регулярную стирку не только простыней, но также одеял и подушек. Не забывайте стирать их при температуре не менее 60 градусов по Цельсию, поскольку высокие температуры убивают постельных клещей, и гладьте все, что можно потом гладить.",
        image: t11_3,
      },
      {
        name: "Используйте чистящие средства, подходящие для уборки при аллергии.",
        text: "Многие традиционные чистящие средства содержат химические вещества, которые могут вызвать аллергическую реакцию: иногда даже продукты с сильным запахом могут оказаться слишком сильными для чувствительных людей. Чтобы этого избежать, важно искать чистящие средства, специально отмеченные как безопасные для астматиков и аллергиков. Однако использование натуральных ингредиентов для очистки всегда является эффективным и экологически чистым вариантом!",
        image: t11_4,
      },
      {
        name: "Наведите порядок в своем пространстве",
        text: "Гораздо легче поддерживать регулярную, безопасную для аллергиков процедуру уборки, если под рукой нет ненужных вещей. Благодаря тому, что на столешницах и поверхностях нет беспорядка, вытереть пыль влажной тряпкой можно в кратчайшие сроки!",
        image: t11_4,
      },
      {
        name: "Заключение",
        text: "Чистый дом делает вашу жизнь проще и приятнее. Если вы страдаете аллергией, обеспечив безупречную чистоту в доме, вы облегчите многие симптомы и улучшите свое здоровье.",
        image: logo,
      },
    ],
  },
  {
    name: "7 лучших методов предотвращения появления токсичной плесени дома",
    content: [
      {
        name: "Плесень - враг здоровой жизни",
        text: "Плесень – маленькое слово, но оно способно заставить любого человека съежиться и вздрогнуть. Хотя она полезна для разложения органических веществ, необнаруженный рост плесени может быть очень вредным для вашего дома. Будь то потолочная плитка или внутренние стены конденсирующихся и протекающих труб, пищевая бумага, одежда и ковер, или задняя сторона гипсокартона, плесень может поселиться где угодно. Это не только влияет на ваше здоровье и является серьезной проблемой, но и устранение плесени обходится довольно дорого. Вы беспокоитесь о том, как предотвратить появление токсичной плесени дома? Вот несколько способов профилактики, прежде чем плесень станет неразрешимой проблемой.",
        image: logo,
      },
      {
        name: "Влага - причина плесени",
        text: "Влага – большая проблема и причина роста плесени в доме. Поддержание сухости влажных помещений может оказать большую помощь в предотвращении появления токсичной плесени в доме. Например, просачивание дождевой воды в подвал или скопление протекающих труб могут мгновенно привести к появлению плесени. Даже мокрый ковер из-за пролитой воды или мокрая одежда в стиральной машине, а также мокрые стены после принятия душа могут способствовать распространению плесени в доме. ",
        image: logo,
      },
      {
        name: "Ликвидируем условия появления плесени",
        text: "Самый эффективный способ борьбы с плесенью — постоянная ликвидация условий для ее появления и роста. Регулярно вытирайте пыль и мойте полы в квартире, но обязательно высушивайте следы влажной уборки. Проветривайте все помещения. Особенно внимательно следите за ванными комнатами и кухнями. При работе с участками, зараженными плесенью, надевайте на лицо респиратор или медицинскую маску, а на руки — перчатки. Это предохранит легкие от вдыхания плесневых спор, а кожу от прямого контакта с грибком.",
        image: logo,
      },
      {
        name: "Перекись водорода",
        text: "Противогрибковый и антибактериальный раствор, который есть во многих аптечках, — хорошее средство от плесени. Налейте в распылитель перекись водорода с концентрацией 3%. Его можно использовать на кухонной технике, столешницах, ванне, твердых полах и поверхностях стен. Сначала проверьте незаметный участок, чтобы предотвратить обесцвечивание или повреждение. Пропитайте заплесневелую поверхность жидкостью и оставьте на десять минут. Затем потрите область с усилием, достаточным для удаления пятен, и вытрите насухо.",
        image: logo,
      },
      {
        name: "Эфирные масла",
        text: "Многие эфирные масла используются не только для ароматерапии. Эссенции обладают антисептическими и дезодорирующими свойствами, отпугивают насекомых и помогают избавиться от плесени. Масло чайного дерева, розмарина и лаванды — натуральные фунгициды, который быстро очищают грибковые споры и предотвращают их повторное появление. Смешайте одну чайную ложку масла чайного дерева с 200 мл чистой воды, хорошо взболтайте в закрытой емкости. Распылите средство на заплесневелый участок, оставьте высыхать на час. Затем протрите поверхность сухим полотенцем или тряпкой из микрофибры. Некоторые эфирные масла раздражают кожу, поэтому рекомендуется использовать перчатки.",
        image: logo,
      },
      {
        name: "Белый уксус",
        text: "Слабокислый пищевой продукт очищает и дезинфицирует. Уксус безопасен для большинства поверхностей, а специфический запах быстро выветривается. Он может убить 82% видов плесени, включая сложную черную. Лучше использовать неразбавленную кислоту, так повышается ее эффективность. Налейте белый уксус в пульверизатор, распылите на заплесневелую поверхность и оставьте на час. Протрите участок чистой водой и дайте ему высохнуть. Уксус испарится в течение пары часов и запах исчезнет.",
        image: logo,
      },
      {
        name: "Лимоны",
        text: "Лимон может быть антисептиком, отбеливателем, пятновыводителем и очистителем. Этот фрукт поможет удалить черную плесень. Высокая концентрация кислоты в лимонах разрушает мицелий, помогает легко удалить налет и дезинфицирует поверхность. Выдавите сок трех — пяти лимонов в чашку и нанесите его на заплесневелый участок. Оставьте на пять минут, а затем протрите поверхность влажным полотенцем. Смесь можно наносить повторно для наилучшего очищения и не смывать ее",
        image: logo,
      },
      {
        name: "Пищевая сода",
        text: "У соды множество преимуществ в бытовом использовании, это отличное чистящее и дезинфицирующее средство. Она безопасна для детей и домашних животных, поэтому нередко входит в состав профессиональных средств для уборки. Помимо удаления плесени, сода отлично впитывает запахи и влагу. Смешайте половину столовой ложки соды с 0,5 л воды и взболтайте в пульверизаторе. Распылите состав на пораженные плесенью участки, удалите налет щеткой и ополосните водой. После этого снова нанесите содовый раствор и оставьте высыхать. Этот способ позволяет уничтожить оставшуюся плесень и предотвратит появление новой.",
        image: logo,
      },
      {
        name: "Антисептическая грунтовка",
        text: "Проверенный способ с эффективным средством из строительного магазина. Перед нанесением грунтовки на зараженную поверхность постарайтесь максимально счистить с нее плесень. В случае глубокого прорастания мицелия со стен удаляют не только обои и краску, но также слои штукатурки вплоть до бетона и кирпича. Раствор продается как в готовом виде, так и для разведения водой.",
        image: logo,
      },
    ],
  },
];
