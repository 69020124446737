import { useEffect, useState } from "react";
import styles from "./slidestring.module.css";
import { AnimatePresence, motion } from "framer-motion";
import dot from "../../images/roundbw.png";
import dota from "../../images/roundbwa.png";

type TOrderypeprops = {
  list: string[];
  delay: number;
};

type Tdefaults = {
  index: number;
  step: number;
  active: boolean;
};
const defaults: Tdefaults = {
  index: 0,
  step: 1,
  active: true,
};

const backgraunddot = {
  backgroundImage: `url(${dot})`,
};
const backgraunddota = {
  backgroundImage: `url(${dota})`,
};
type TMyComponent = {
  isVisible: boolean;
};

function SlideString(props: TOrderypeprops) {
  const strings = props.list;
  const delay = props.delay * 1000;

  const [isVisible, setIsVisible] = useState(true);
  const [one, setOne] = useState(true);
  const [isVisibleuser, setIsVisibleuser] = useState(false);
  const [state, setState] = useState<Tdefaults>({
    index: 0,
    step: 1,
    active: true,
  });

  useEffect(() => {
    setState(() => ({ ...defaults }));
  }, [strings]);

  useEffect(() => {
    let delaym = delay;
    if (isVisible) {
      const timeoutId = setTimeout(setState, delaym, ({ ...state }) => {
        if (state.active) {
          state.index += state.step;
          state.active = false;
          if (state.index === strings.length - 1) {
            state.step = -1;
          } else if (state.index === 0) {
            state.step = 1;
          }
        } else {
          state.active = true;
        }
        return state;
      });

      return () => clearTimeout(timeoutId);
    }
  });

  if (state === null) {
    return null;
  }

  function removeItem() {
    if (state.index === strings.length - 1) {
      setState({
        index: 0,
        step: 1,
        active: false,
      });
    } else {
      setState({
        index: state.index + 1,
        step: 1,
        active: false,
      });
    }
    setIsVisibleuser(true);
  }

  function dragStartHendler() {
    removeItem();
  }

  function onHoverStart(index: number) {
    setIsVisible(false);
    setState({
      index: state.index,
      step: 1,
      active: true,
    });
  }

  function onHoverEnd() {
    setIsVisible(true);
    if (state.index > strings.length - 1) {
      setState({
        index: 0,
        step: 1,
        active: false,
      });
    } else {
      setState({
        index: state.index,
        step: 1,
        active: false,
      });
    }
    setIsVisibleuser(false);
  }

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        {!isVisibleuser && (
          <AnimatePresence>
            {state.active && (
              <motion.div
                className={styles.item}
                key={state.index}
                initial={{ x: -300, opacity: 0 }}
                exit={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0,
                  duration: 1,
                }}
                draggable={true}
                onHoverStart={() => onHoverStart(state.index)}
                onDragStart={() => dragStartHendler()}
                onTouchStart={() => dragStartHendler()}
                onHoverEnd={() => onHoverEnd()}
                onTouchCancel={() => onHoverEnd()}
              >
                {strings[state.index]}
              </motion.div>
            )}
          </AnimatePresence>
        )}
        {isVisibleuser && (
          <motion.div
            className={styles.item}
            key={state.index}
            initial={{ x: -300, opacity: 0 }}
            exit={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
            draggable={true}
            onDragStart={() => dragStartHendler()}
            onTouchStart={() => dragStartHendler()}
            onTouchCancel={() => onHoverEnd()}
            onHoverEnd={() => onHoverEnd()}
          >
            {strings[state.index]}
          </motion.div>
        )}
      </div>
      <div className={`${styles.dots}`}>
        {strings.map((item, index) => {
          if (index === state.index) {
            return (
              <div
                className={styles.dot}
                style={backgraunddot}
                key={index}
              ></div>
            );
          } else {
            return (
              <div
                className={styles.dot}
                style={backgraunddota}
                key={index}
              ></div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default SlideString;
