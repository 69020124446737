import React, { VFC, useState } from "react";
import styles from "./flat.module.css";
import imageflat from "../../images/imageflat.png";
import imageflatsfrom from "../../images/image098min.png";
import bedroom from "../../images/bedroom.png";
import { motion, AnimatePresence } from "framer-motion";
import { Form, NavLink } from "react-router-dom";
import OrderLink from "../orderlink/orderlink";
import None from "../none/none";
import { useResize } from "../../utils/hook";
import { useEffect } from "react";
import RunString from "../runstring/runstring";
import SlideString from "../slidestring/slidestring";

const listWork = [
  "Пробная уборка за полцены",
  "Скидка на все заказы в июле 30%",
  "Сегодня скидка на срочную уборку 30%",
];

const textAnimation = {
  hidden: {
    x: -1000,
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 1, duration: 1 },
  }),
};

const Flat = () => {
  const wl = useResize().isScreenSm;
  const [images, setImages] = useState<string>(imageflat);

  useEffect(() => {
    if (wl) {
      setImages(imageflat);
    } else {
      setImages(imageflatsfrom);
    }
  }, [wl]);

  const backgraundMain = {
    backgroundImage: `url(${images})`,
  };

  return (
    <motion.div
      className={styles.main}
      style={backgraundMain}
      initial="hidden"
      whileInView="visible"
    >
      <motion.div
        className={styles.section_1}
        initial="hidden"
        whileInView="visible"
      >
        <motion.h1 className={styles.text} custom={1} variants={textAnimation}>
          Русскоговорящий клининг в Порту.
        </motion.h1>

        <motion.div variants={textAnimation} custom={2}>
          <OrderLink
            size={150}
            name="Заказать уборку"
            link="/order"
          ></OrderLink>
        </motion.div>
      </motion.div>
      <None hh={100}></None>
    </motion.div>
  );
};

export default Flat;
