import { MouseEventHandler, useEffect, useRef, useState } from "react";
import styles from "./contact.module.css";
import { AppHeader } from "../app-header/app-header";
import { motion, AnimatePresence } from "framer-motion";
import { Slade } from "../slade/slade";
import Video from "../video/video";
import Flat from "../flat/flat";
import Compound from "../compound/compound";
import { dataCompany } from "../../utils/data";
import { dataCall } from "../../utils/data";
import { listtext } from "../../utils/data";

import Cleaning from "../cleaning/cleaning";
import AppBottom from "../app-bottom/app-bottom";
import iconfon from "../../images/iconfon.jpg";
import Runstring from "../runstring/runstring";
import Typewriter from "../writestring/writestring";
import Cardcompany from "../cardcompany/cardcompany";
import Cardсall from "../cardсall/cardсall";

function Contact() {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.section_1}>
        <h2>Как с нами связаться?</h2>
        <div className={styles.list}>
          {dataCall.map((item, index) => {
            return (
              <div className={styles.item} key={index}>
                <Cardсall
                  name={item.name}
                  actionType={item.actionType}
                  icon={item.icon}
                  iconw={item.iconw}
                  key={index}
                  link={item.link}
                ></Cardсall>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Contact;
/*useEffect(() => {}, []);
  const [isactiv, setIsactiv] = useState(0);*/
