import styles from "./none.module.css";

import iconfon from "../../images/iconfon.jpg";

type Tnone = {
  hh: number;
};

function None(props: Tnone) {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
    height: `${props.hh}px`,
  };

  return <div className={styles.main} style={backgraundMain}></div>;
}

export default None;
