import React, { useEffect, useState } from "react";
import styles from "./formaddress.module.css";

type Tadress = {
  func: React.Dispatch<React.SetStateAction<string>>;
};

const FormAddress = (props: Tadress) => {
  const setdata = props.func;
  const [district, setDistrict] = useState("");
  const [street, setStreet] = useState("");
  const [door, setDoor] = useState("");
  const [flat, setFlat] = useState("");
  const [flor, setFlor] = useState("");

  const changeDistr = (e: any) => {
    setDistrict(e.target.value);
  };
  const changeStreet = (e: any) => {
    setStreet(e.target.value);
    setdata(
      `район:${district}, улица:${street}, дверь:${door}, квартира:${flat}, этаж:${flor}`
    );
  };

  const changeDoor = (e: any) => {
    setDoor(e.target.value);
    setdata(
      `район:${district}, улица:${street}, дверь:${door}, квартира:${flat}, этаж:${flor}`
    );
  };

  const changeFlat = (e: any) => {
    setFlat(e.target.value);
    setdata(
      `район:${district}, улица:${street}, дверь:${door}, квартира:${flat}, этаж:${flor}`
    );
  };
  const changeFlor = (e: any) => {
    setFlor(e.target.value);
    setdata(
      `район:${district}, улица:${street}, дверь:${door}, квартира:${flat}, этаж:${flor}`
    );
  };
  return (
    <div className={styles.main}>
      <h3 className={styles.text}>Район</h3>
      <input
        className={styles.userBox}
        type="text"
        value={district}
        name="district"
        onChange={changeDistr}
        placeholder="Район"
      />
      <h3 className={styles.text}>Улица</h3>
      <input
        className={styles.userBox}
        type="text"
        value={street}
        name="street"
        onChange={changeStreet}
        placeholder="Улица"
      />
      <h3 className={styles.text}>Дверь</h3>
      <input
        className={styles.userBox}
        type="text"
        value={door}
        name="door"
        onChange={changeDoor}
        placeholder="Дверь"
      />
      <h3 className={styles.text}>Квартира</h3>
      <input
        className={styles.userBox}
        type="text"
        value={flat}
        name="flat"
        onChange={changeFlat}
        placeholder="Квартира"
      />
      <h3 className={styles.text}>Этаж</h3>
      <input
        className={styles.userBox}
        type="text"
        value={flor}
        name="flor"
        onChange={changeFlor}
        placeholder="Этаж"
      />
      <h4 className={styles.text}>Мы работаем только в зоне Большого Порту</h4>
    </div>
  );
};

export default FormAddress;
