import {useEffect,useState } from "react";
import styles from "./wecan.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import { dataCompany } from "../../utils/data";

import { listtext } from "../../utils/data";
import dot from "../../images/roundbw.png";
import dota from "../../images/roundbwa.png";


import iconfon from "../../images/iconfon.jpg";
import Runstring from "../runstring/runstring";

import Cardcompany from "../cardcompany/cardcompany";

import { useResize } from "../../utils/hook";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const backgraunddot = {
  backgroundImage: `url(${dot})`,
};
const backgraunddota = {
  backgroundImage: `url(${dota})`,
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

function divIndexset(index: number, col: number, imageIndex: number) {
  let nom = 0;

  if (imageIndex === 0) {
    nom = index;
  }
  if (imageIndex > 0) {
    if (index + col > dataCompany.length - 1) {
      nom = index;
    } else {
      nom = index + imageIndex * col;
    }
  }

  return nom;
}

function listi(item: number) {
  const listw = [];
  if (dataCompany.length > item) {
    if (dataCompany.length % item === 0) {
      let rr = Math.floor(dataCompany.length / item);
      for (let i = 0; i < rr; i++) {
        listw.push(i);
      }
    } else {
      let rr = Math.floor(dataCompany.length / item) + 1;
      for (let i = 0; i < rr; i++) {
        listw.push(i);
      }
    }
  } else {
    listw.push(1);
  }
  return listw;
}

function Wecan() {
  const [[page, direction], setPage] = useState([0, 0]);
  const wl = useResize().width;
  const [col, setCol] = useState([1, 2, 3, 4]);
  const [colpage, setColpage] = useState([1]);
  const [dotss, setdotss] = useState(false);
  const [widthd, setwidthd] = useState({ width: `1150px` });

  useEffect(() => {
    if (wl > 1200) {
      setCol([1, 2, 3, 4]);
      setColpage(listi(4));
      if (listi(4).length > 1) {
        setdotss(true);
      } else {
        setdotss(false);
      }
      setwidthd({ width: `1150px` });
    } else {
      if (wl > 900) {
        setCol([1, 2, 3]);
        setColpage(listi(3));
        if (listi(3).length > 1) {
          setdotss(true);
        } else {
          setdotss(false);
        }
        setwidthd({ width: `860px` });
      } else {
        if (wl > 650) {
          setCol([1, 2]);
          setColpage(listi(2));
          if (listi(2).length > 1) {
            setdotss(true);
          } else {
            setdotss(false);
          }
          setwidthd({ width: `550px` });
        } else {
          setCol([1]);
          setColpage(listi(1));
          if (listi(1).length > 1) {
            setdotss(true);
          } else {
            setdotss(false);
          }
          setwidthd({ width: `300px` });
        }
      }
    }
  }, [wl]);

  const imageIndex = wrap(0, colpage.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.section_1}>
        <h1 className={styles.text}>
          Мы компания домашнего сервиса и мы можем:
        </h1>
        <h2 className={styles.text}>Сделать вашу жизнь ярче</h2>
        <h3 className={styles.text}>
          Eсли не тратить драгоценное время на домашнюю рутину, можно...
        </h3>
        <div className={styles.strings}>
          <Runstring strings={listtext} delay={70}></Runstring>
        </div>
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Сделать вашу жизнь проще</h2>
        <h3 className={styles.text}>потому что у нас</h3>

        <div className={styles.imgblock} style={widthd}>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className={styles.image}
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              {col.map((item, index) => {
                return (
                  <Cardcompany
                    name={
                      dataCompany[divIndexset(index, col.length, imageIndex)]
                        .name
                    }
                    actionType={
                      dataCompany[divIndexset(index, col.length, imageIndex)]
                        .actionType
                    }
                    icon={
                      dataCompany[divIndexset(index, col.length, imageIndex)]
                        .icon
                    }
                    iconw={
                      dataCompany[divIndexset(index, col.length, imageIndex)]
                        .iconw
                    }
                    key={index}
                  ></Cardcompany>
                );
              })}
            </motion.div>
          </AnimatePresence>
          {dotss && (
            <div className={styles.dotblock}>
              {colpage.map((item, index) => {
                if (index === imageIndex) {
                  return (
                    <div
                      className={styles.dot}
                      style={backgraunddot}
                      key={index}
                    ></div>
                  );
                } else {
                  return (
                    <div
                      className={styles.dot}
                      style={backgraunddota}
                      key={index}
                    ></div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Wecan;
