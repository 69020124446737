import { useEffect, useState } from "react";
import styles from "./cardсall.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import IconLink from "../iconlink/iconlink";
import Formcall from "../formcall/formcall";

type Tprops = {
  name: string;
  actionType: string;
  icon: string;
  iconw: string;
  link: string;
};

const Cardсall = (props: Tprops) => {
  const homeone = props.icon;
  const hometwo = props.iconw;

  const [isactivcall, setActivecall] = useState(false);

  useEffect(() => {
    if (isactivcall === true) {
      document.addEventListener("click", hendlerClick_2);
    }
  }, [isactivcall]);

  const hendlerClick_2 = (evt: any) => {
    if (isactivcall === true) {
      if (!evt.target.className.includes("icon")) {
        if (!evt.target.className.includes("form")) {
          setActivecall(false);
          document.removeEventListener("click", hendlerClick_2);
        }
      }
    }
  };

  if (props.name === "Заказать звонок на сайте, можно тут") {
    return (
      <motion.div
        className={styles.main}
        whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
      >
        <div className={styles.name}>{props.name}</div>
        <div id="call" onClick={() => setActivecall(true)}>
          <IconLink
            homeone={homeone}
            hometwo={hometwo}
            rad={150}
            action={false}
          ></IconLink>
        </div>
        <div className={styles.text}>{props.actionType}</div>
        {isactivcall && (
          <div className={styles.form}>
            <Formcall></Formcall>
          </div>
        )}
      </motion.div>
    );
  } else {
    return (
      <motion.div
        className={`${styles.main}`}
        whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
      >
        <div className={styles.name}>{props.name}</div>
        <NavLink to={props.link}>
          <IconLink
            homeone={homeone}
            hometwo={hometwo}
            rad={150}
            action={false}
          ></IconLink>
        </NavLink>
        <div className={styles.text}>{props.actionType}</div>
      </motion.div>
    );
  }
};

export default Cardсall;
