import styles from "./deepclean.module.css";

import { listLocG } from "../../utils/data";

import Status from "../../components/status/status";
import Compoundnew from "../../components/compoundnew/compoundnew";

function Deepclean() {
  return (
    <div className={styles.main}>
      <div className={styles.section_1}>
        <Status
          nameone="Услуги"
          nametwo="Генеральная уборка"
          namethree="Что мы делаем"
          linkone="/clean"
        ></Status>
      </div>
      <div className={styles.section_1}>
        <div className={styles.containerflat}>
          <Compoundnew listloc={listLocG}></Compoundnew>
        </div>
      </div>
    </div>
  );
}

export default Deepclean;
