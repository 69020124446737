import styles from "./advicepage.module.css";
import Advice from "../../components/advice/advice";

function AdvicePage() {
  return (
    <div className={styles.main}>
      <div className={styles.section_1}>
        <Advice></Advice>
      </div>
    </div>
  );
}

export default AdvicePage;
