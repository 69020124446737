import { motion } from "framer-motion";
import styles from "./app-bottom.module.css";
import dog from "../../images/logo_2.png";
import { NavLink } from "react-router-dom";
import Setlink from "../setlink/setlink";
import { listMenu } from "../../utils/data";
import SlideWay from "../sladeway/slideway";

export function AppBottom() {
  return (
    <div className={styles.main}>
      <div className={styles.part}>
        <SlideWay></SlideWay>
      </div>

      <div className={styles.part}>
        <nav className={styles.menu}>
          <ul className={styles.menulist}>
            {listMenu.map((item, index) => {
              return (
                <motion.div whileHover={{ scale: 1.2 }} key={index}>
                  <NavLink className={styles.menuitem} to={item.link}>
                    {item.name}
                  </NavLink>
                </motion.div>
              );
            })}
          </ul>
        </nav>

        <div className={styles.mainpart}>
          <NavLink className={styles.preview} to={"/order"}>
            <motion.img
              src={dog}
              alt="logo"
              animate={{ rotate: 360 }}
              transition={{
                duration: 2,
                repeat: 1,
                repeatDelay: 1,
                repeatType: "mirror",
                type: "tween",
              }}
            />
          </NavLink>
        </div>
        <div className={styles.mainpart}>
          <Setlink></Setlink>
        </div>
      </div>
    </div>
  );
}
export default AppBottom;
