import styles from "./video.module.css";
import video1 from "../../assets/video1.mp4";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    x: -1000,
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 1, duration: 1 },
  }),
};

const Video = () => {
  return (
    <div className={styles.main}>
      <video className={styles.video} src={video1} autoPlay loop muted />
      <motion.div
        className={styles.textM}
        initial="hidden"
        whileInView="visible"
      >
        <motion.h1
          className={styles.text1M}
          custom={1}
          variants={textAnimation}
        >
          Проведите время с пользой...
        </motion.h1>
        <motion.h2
          className={styles.text2M}
          custom={2}
          variants={textAnimation}
        >
          А уборку оставьте нам
        </motion.h2>
      </motion.div>
    </div>
  );
};

export default Video;

/*<div className={styles.main}>
<video className={styles.video} src={video1} autoPlay loop muted />
<motion.div className={styles.textM}
initial="hidden"
whileInView="visible">
  <motion.h1
    className={styles.text1M}
    custom={1}
    variants={textAnimation}
  >
    Проведите время с пользой...
  </motion.h1>
  <motion.h2
    className={styles.text2M}
    custom={5}
    variants={textAnimation}
  >
    А уборку оставьте нам
  </motion.h2>
</motion.div>
</div>*/
