import styles from "./cardbase.module.css";
import homeone from "../../images/home01.png";
import hometwo from "../../images/home02.png";
import IconLink from "../iconlink/iconlink";

type Tprops = {
  name: string;
  kof: number;
  actionType: string;
  base: number;
};

const Cardbase = (props: Tprops) => {
  const pr = props.kof * props.base;
  let coment = "";

  if (props.actionType === props.name) {
    return (
      <div className={`${styles.mainm} ${styles.main}`}>
        <div className={styles.name}>{props.name}</div>
        <IconLink
          homeone={homeone}
          hometwo={hometwo}
          rad={100}
          action={true}
        ></IconLink>
        <div className={styles.price}>{`€ ${pr.toFixed(2)}`}</div>
      </div>
    );
  } else {
    return (
      <div className={styles.main}>
        <div className={styles.name}>{props.name}</div>
        <IconLink
          homeone={homeone}
          hometwo={hometwo}
          rad={100}
          action={false}
        ></IconLink>
        <div className={styles.price}>{`€ ${pr.toFixed(2)}`}</div>
      </div>
    );
  }
};

export default Cardbase;
