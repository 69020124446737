import styles from "./cardcleandop.module.css";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    x: -1000,
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.5, duration: 1 },
  }),
};

type Tprops = {
  name: string;
  list: string[];
  image: string;
  price: number;
};

const Cardcleandop = (props: Tprops) => {
  const list = props.list;
  return (
    <motion.div
      className={`${styles.main}`}
      whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
    >
      <img className={styles.image} src={props.image} alt={props.name} />
      <div className={styles.name}>{props.name}</div>
      {list.map((item, index) => {
        return (
          <div className={styles.item} key={index}>
            {item}
          </div>
        );
      })}
      <div className={styles.name}>{`от €${props.price} `}</div>
    </motion.div>
  );
};

export default Cardcleandop;
