import styles from "./clean.module.css";

import Cleaning from "../../components/cleaning/cleaning";

import AddServ from "../../components/addserv/addserv";

import SlideWay from "../../components/sladeway/slideway";

function Clean() {
  return (
    <div className={styles.main}>
      <div className={styles.section_1}>
        <Cleaning></Cleaning>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_1}>
        <AddServ></AddServ>
      </div>
    </div>
  );
}

export default Clean;
