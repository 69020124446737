import { useState } from "react";
import styles from "./order.module.css";
import { motion } from "framer-motion";
import Calendar from "../../components/calendar/calendar";
import iconfon from "../../images/iconfon.jpg";
import Cardbase from "../../components/cardbase/cardbase";
import hand from "../../images/ok.png";
import dog from "../../images/logodog.jpg";
import handw from "../../images/okw.png";
import {
  typeClean,
  pricebase,
  pricebaseroom,
  pricebasewc,
  typeCleanbase,
  TtypeClean,
  listworkdop,
  listdop,
  Tlistworkdop,
  timebaseroom,
  timebasewc,
  timebase,
} from "../../utils/data";
import Maismenos from "../../components/maismenos/maismenos";
import Carddop from "../../components/carddop/carddop";
import Maismenoslite from "../../components/maismenoslite/maismenoslite";
import IconColor from "../../components/iconcolor/iconcolor";
import FormAddress from "../../components/formaddress/formaddress";
import FormaContact from "../../components/formacontact/formacontact";
import Watch from "../../components/watch/watch";
import { sendMassege } from "../../api/telegramm";
import None from "../../components/none/none";

type Tdop = {
  dop: Tlistworkdop;
  col: number;
};
const textAnimationt = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 0, duration: 0.2 },
  }),
};

function Order() {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };
  const [ok, setok] = useState(false);
  const [oktext, setoktext] = useState("");
  const [error, setError] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [actionType, setActionType] = useState(typeCleanbase);
  const [data, setdata] = useState("-");
  const [timework, settimework] = useState("-");
  const [adress, setadress] = useState("-");
  const [contact, setcontact] = useState("-");
  const [room, setRoom] = useState(1);
  const [roomn, setRoomn] = useState("комната");
  const [wc, setWc] = useState(1);
  const [wcn, setWcn] = useState("туалет");
  const [active, setActive] = useState(false);
  const [time, settime] = useState(4);
  const [costbase, setCostbase] = useState(
    (pricebaseroom * room + pricebasewc * wc + pricebase) * actionType.kof
  );
  const [costdop, setCostdop] = useState(0);
  const [dopone, setDopone] = useState(
    "Хочу заказать только дополнительные услуги"
  );
  const [dop, setDop] = useState([
    {
      dop: {
        name: "Новые спонжи и тряпочки, необходимые чистящие средства",
        price: 0,
      },
      col: 1,
    },
  ]);

  const onClickType = (it: TtypeClean) => {
    setActionType(it);
  };

  const changCleaning = () => {
    if (dopone === "Хочу заказать только дополнительные услуги") {
      setDopone("Хочу заказать доп. услуги с основной уборкой");
    } else {
      setDopone("Хочу заказать только дополнительные услуги");
    }
  };
  const changname = () => {
    let namecleaning = "";
    if (dopone === "Хочу заказать только дополнительные услуги") {
      namecleaning = `${actionType.name} квартиры запланирована на ${data} ${timework} (описание квартиры: ${room} ${roomn}, ${wc} ${wcn}, коридор и кухня) `;
    } else {
      namecleaning = `Уборка запланирована на ${data} ${timework}`;
    }
    return namecleaning;
  };

  const okdop = (item: Tlistworkdop) => {
    const post = dop.find((post) => post.dop.name === item.name);
    if (post) {
      return true;
    } else {
      return false;
    }
  };

  const sumDop = () => {
    const initialValue = 0;
    const sumWithInitial = Math.round(
      dop.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.col * currentValue.dop.price,
        initialValue
      )
    );
    return sumWithInitial;
  };

  const sumBase = () => {
    let sumWithInitial = 0;
    if (dopone === "Хочу заказать только дополнительные услуги") {
      sumWithInitial = Math.round(
        (pricebaseroom * room + pricebasewc * wc + pricebase) * actionType.kof
      );
    }

    return sumWithInitial;
  };

  const sumTime = () => {
    let timeWithInitial = 0;
    if (dopone === "Хочу заказать только дополнительные услуги") {
      timeWithInitial = Math.round(
        (timebaseroom * room + timebasewc * wc + timebase) * actionType.koftime
      );
    } else {
      timeWithInitial = 2;
    }

    return timeWithInitial;
  };

  const massegeform = () => {
    let dopp = "";
    {
      dop.map((item, index) => {
        dopp =
          dopp +
          ` и ${item.dop.name} -  €${item.dop.price} x ${item.col} = €${
            item.dop.price * item.col
          }`;
      });
    }
    const message = `Заказ на ${changname} на сумму ${sumBase()}
    и доп. услуги(${dopp}) на сумму ${sumBase() + sumDop()}
     назначена на ${data} ${timework}  по адресу ${adress} на ${contact} `;
    return message;
  };

  const Orderclick = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendMassege(massegeform());
      setok(true);
      setoktext("");
    } catch (e) {
      setError(true);
    } finally {
      setFormValid(false);
    }
  };

  const changecomm = (e: any) => {
    setoktext(e.target.value);
  };

  const onClickdop = (it: any, col: number) => {
    let list = dop.slice(0, dop.length);
    const post = list.find((post) => post.dop.name === it.name);
    if (post) {
      if (post.col + col === 0) {
        list = list.filter((item) => item.dop.name !== it.name);
      } else {
        post.col = post.col + col;
      }
    } else {
      const listnov = {
        dop: {
          name: it.name,
          price: it.price,
        },
        col: col,
      };
      list.push(listnov);
    }
    setDop(list);
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.flat}>
        <h1>{`Расчет стоимости уборки`}</h1>
        <h2>{`В вашей квартире:`}</h2>
        <div className={styles.mais}>
          <Maismenos
            nameone="комната"
            nametwo="комнаты"
            namethree="комнат"
            func={setRoom}
            funcn={setRoomn}
          ></Maismenos>
          <Maismenos
            nameone="туалет"
            nametwo="туалета"
            namethree="туалета"
            func={setWc}
            funcn={setWcn}
          ></Maismenos>
        </div>
      </div>
      <div className={styles.section_2}>
        {typeClean.map((item, index) => {
          return (
            <div
              className={styles.typeclean}
              onClick={() => onClickType(item)}
              key={index}
            >
              <Cardbase
                name={item.name}
                kof={item.kof}
                key={index}
                actionType={actionType.name}
                base={pricebaseroom * room + pricebasewc * wc + pricebase}
              ></Cardbase>
            </div>
          );
        })}
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Дополнительные работы</h2>

        <div className={styles.section_2d}>
          {listworkdop.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <Carddop it={item}></Carddop>
                <IconColor
                  homeone={hand}
                  hometwo={handw}
                  rad={80}
                  action={okdop(item)}
                ></IconColor>
                <Maismenoslite
                  func={onClickdop}
                  param={item}
                  sizew={120}
                  sizeh={50}
                ></Maismenoslite>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Дополнительные услуги</h2>

        <div className={styles.section_2d}>
          {listdop.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <Carddop it={item}></Carddop>
                <IconColor
                  homeone={hand}
                  hometwo={handw}
                  rad={80}
                  action={okdop(item)}
                ></IconColor>
                <Maismenoslite
                  func={onClickdop}
                  param={item}
                  sizew={120}
                  sizeh={50}
                ></Maismenoslite>
              </div>
            );
          })}
        </div>
        <button className={styles.button} onClick={changCleaning}>
          {dopone}
        </button>
      </div>

      <div className={styles.section}>
        <div className={styles.calendar}>
          <h2 className={styles.text}>Запланируйте дату уборки</h2>
          <Calendar func={setdata}></Calendar>
        </div>
        <div className={styles.calendar}>
          <h2 className={styles.text}>Запланируйте время уборки</h2>
          <Watch func={settimework} dura={sumTime()} data={data}></Watch>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.adres}>
          <h2 className={styles.text}>Ваш адрес</h2>
          <FormAddress func={setadress}></FormAddress>
        </div>

        <div className={styles.adres}>
          <h2 className={styles.text}>Как с вами связаться?</h2>
          <FormaContact func={setcontact} funcok={setFormValid}></FormaContact>
        </div>
      </div>
      <div className={styles.section_c}>
        <h6>Комментарий</h6>
        <input
          className={styles.userBox}
          type="text"
          value={oktext}
          name="commentar"
          onChange={changecomm}
          placeholder="Напишите нам: удобное время для связи, дополнительные
          запросы и т.п."
        />
      </div>
      <div className={styles.order_1}>
        <div className={styles.order_2}>
          <h2 className={styles.ordertext}>Ваш заказ:</h2>
          <h3 className={styles.block}>{changname()}</h3>
          <h3 className={styles.blockprice}>{`Стоимость с доп. работами: € ${
            sumBase() + sumDop()
          }`}</h3>
          {!active && (
            <div>
              <div className={styles.click} onClick={() => setActive(true)}>
                Показать подробности
              </div>
              <div className={styles.click} onClick={() => setActive(true)}>
                +
              </div>
            </div>
          )}
          {active && (
            <div>
              <div className={styles.click} onClick={() => setActive(false)}>
                Свернуть
              </div>
              <div className={styles.click} onClick={() => setActive(false)}>
                -
              </div>
              <h3
                className={styles.blockprice}
              >{`Стоимость уборки: € ${sumBase()}`}</h3>
              <h3>{`Дополнительные работы:`}</h3>

              {dop.map((item, index) => {
                return (
                  <div key={index}>
                    <h4>{`${item.dop.name} -  €${item.dop.price} x ${
                      item.col
                    } = €${item.dop.price * item.col}`}</h4>
                  </div>
                );
              })}
              <h3
                className={styles.blockprice}
              >{`Стоимость доп. работ: € ${sumDop()}`}</h3>
              <h3>{`Примерное время работы: ${sumTime()} ч.`}</h3>
            </div>
          )}
        </div>
      </div>

      {formValid && (
        <motion.button
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={styles.button}
          onClick={Orderclick}
          variants={textAnimationt}
        >
          Отправить заказ
        </motion.button>
      )}
      {ok && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={styles.textform}
        >
          <div className={styles.section_1}>
            <p>Спасибо за заказ, Белый шпиц желает Вам здоровья!</p>
            <img className={styles.imaged} src={dog} alt="pictura" />
            <p>
              {`${massegeform()} успешно отправлен, мы перезвоним для уточнения ближайшее время (с 9 до 18 часов в течение 30 мин.)`}
            </p>
          </div>
        </motion.div>
      )}
      {error && (
        <>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className={styles.textform}
          >
            Что-то не так,пожалуйста, попробуйте написать в телеграмм
          </motion.div>
        </>
      )}
      <></>
      <None hh={100}></None>
    </div>
  );
}

export default Order;
