import styles from "./regularclean.module.css";
import { listLoc } from "../../utils/data";
import Status from "../../components/status/status";
import Compoundnew from "../../components/compoundnew/compoundnew";

function Regularclean() {
  return (
    <div className={styles.main}>
      <div className={styles.section_1}>
        <Status
          nameone="Услуги"
          nametwo="Базовая уборка"
          namethree="Что мы делаем"
          linkone="/clean"
        ></Status>
      </div>

      <div className={styles.section_1}>
        <div className={styles.containerflat}>
          <Compoundnew listloc={listLoc}></Compoundnew>
        </div>
      </div>
    </div>
  );
}

export default Regularclean;
