import React, { VFC, useState } from "react";
import styles from "./compoundnew.module.css";
import imageflat from "../../images/imageflat.png";
import kitchen from "../../images/kitchen.png";
import bedroom from "../../images/bedroom.png";
import toilet from "../../images/toilet.png";
import coridor from "../../images/coridor.png";
import { motion, AnimatePresence } from "framer-motion";
import { TlistLoc } from "../../utils/data";
import { Typeloc } from "../../utils/data";
import iconfon from "../../images/iconfon.jpg";
import OrderLink from "../orderlink/orderlink";
import SlideString from "../slidestring/slidestring";

const textAnimation = {
  hidden: {
    y: -1000,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.5, duration: 1 },
  }),
};

type Tprops = {
  listloc: TlistLoc[];
};

const Compoundnew = (props: Tprops) => {
  const listLoc = props.listloc;
  const [background, setBackgraund] = useState({
    backgroundImage: `url(${bedroom})`,
  });

  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  const [listWork, setlistWork] = useState(listLoc[0].work);
  const [room, setRoom] = useState(listLoc[0].ref);

  const onClick = (loc: Typeloc) => {
    if (loc.ref === "Room") {
      setBackgraund({ backgroundImage: `url(${bedroom})` });
    }
    if (loc.ref === "Kitchen") {
      setBackgraund({ backgroundImage: `url(${kitchen})` });
    }
    if (loc.ref === "Coredor") {
      setBackgraund({ backgroundImage: `url(${coridor})` });
    }
    if (loc.ref === "wc") {
      setBackgraund({ backgroundImage: `url(${toilet})` });
    }
    setlistWork(loc.work);
    setRoom(loc.ref);
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.menu}>
        {listLoc.map((item) => {
          if (item.ref === room) {
            return (
              <motion.button
                className={`${styles.button} ${styles.buttonaction}`}
                onClick={() => onClick(item)}
                whileHover={{ scale: 1.05 }}
                key={item.name}
              >
                {item.name}
              </motion.button>
            );
          } else {
            return (
              <motion.button
                className={styles.button}
                onClick={() => onClick(item)}
                whileHover={{ scale: 1.05 }}
                key={item.name}
              >
                {item.name}
              </motion.button>
            );
          }
        })}
      </div>

      <AnimatePresence>
        <motion.div
          style={background}
          className={styles.fon}
          initial={{ x: -2000, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            duration: 1.5,
          }}
          key={room}
        >
          <div className={styles.slide}>
            <SlideString list={listWork} delay={2}></SlideString>
          </div>
          <div className={styles.link}>
            <OrderLink
              size={150}
              name="Сколько это стоит"
              link="/price"
            ></OrderLink>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Compoundnew;
