import styles from "./carddop.module.css";
import { Tlistworkdop } from "../../utils/data";

type TCarddop = {
  it: Tlistworkdop;
};

const Carddop = (props: TCarddop) => {
  return (
    <div className={`${styles.main}`}>
      <div className={styles.text}>{props.it.name}</div>
      <div className={styles.textt}>€ ${props.it.price}</div>
    </div>
  );
};

export default Carddop;
