import { motion } from "framer-motion";
import styles from "./setlink.module.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import wa from "../../images/wa.png";
import waaction from "../../images/waactive.png";
import IconLink from "../iconlink/iconlink";
import tel from "../../images/tel.png";
import telactive from "../../images/telactive.png";
import tg from "../../images/telegram.png";
import tgaction from "../../images/telegramactive.png";
import Formcall from "../formcall/formcall";

export const Setlink = () => {
  const [isactivcall, setActivecall] = useState(false);

  useEffect(() => {
    if (isactivcall === true) {
      document.addEventListener("click", hendlerClick_2);
    }
  }, [isactivcall]);

  const hendlerClick_2 = (evt: any) => {
    if (isactivcall === true) {
      if (evt.target.className) {
        if (!evt.target.className.includes("call")) {
          if (!evt.target.className.includes("form")) {
            setActivecall(false);
            document.removeEventListener("click", hendlerClick_2);
          }
        }
      }
    }
  };

  return (
    <div className={styles.action}>
      <div className={styles.part}>
        <motion.div
          whileHover={{ scale: 1.4, transition: { duration: 0.2 } }}
          className={styles.call}
          onClick={() => setActivecall(true)}
        >
          Перезвоните мне
        </motion.div>
      </div>
      <div className={styles.part}>
        <motion.div
          className={styles.icon}
          whileHover={{
            scale: 1.4,
            transition: { duration: 0.2 },
          }}
        >
          <NavLink to={"https://t.me/Russiancleaninginporto"}>
            <IconLink
              homeone={tg}
              hometwo={tgaction}
              rad={80}
              action={false}
            ></IconLink>
          </NavLink>
        </motion.div>
        <motion.div
          className={styles.icon}
          whileHover={{ scale: 1.4, transition: { duration: 0.2 } }}
        >
          <NavLink
            to={
              "https://wa.me/351911927602?text=Здравствуйте.%20Хочу%20заказать%20уборку%20перезвоните мне."
            }
          >
            <IconLink
              homeone={wa}
              hometwo={waaction}
              rad={80}
              action={false}
            ></IconLink>
          </NavLink>
        </motion.div>
        <motion.div
          className={styles.icon}
          whileHover={{ scale: 1.4, transition: { duration: 0.2 } }}
        >
          <NavLink to={"href=»tel:351911927602"}>
            <IconLink
              homeone={tel}
              hometwo={telactive}
              rad={80}
              action={false}
            ></IconLink>
          </NavLink>
        </motion.div>

        {isactivcall && (
          <div className={styles.form}>
            <Formcall></Formcall>
          </div>
        )}
      </div>
    </div>
  );
};
export default Setlink;
