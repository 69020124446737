import { MouseEventHandler, useEffect, useRef, useState } from "react";
import styles from "./trabalha.module.css";
import { AppHeader } from "../../components/app-header/app-header";
import { motion, AnimatePresence } from "framer-motion";
import { Slade } from "../../components/slade/slade";
import Video from "../../components/video/video";
import Flat from "../../components/flat/flat";
import Compound from "../../components/compound/compound";
import { dataCompany } from "../../utils/data";
import { dataCall } from "../../utils/data";
import { listtext } from "../../utils/data";

import Cleaning from "../../components/cleaning/cleaning";
import AppBottom from "../../components/app-bottom/app-bottom";
import iconfon from "../../images/iconfon.jpg";
import Runstring from "../../components/runstring/runstring";
import Typewriter from "../../components/writestring/writestring";
import Cardcompany from "../../components/cardcompany/cardcompany";
import Cardсall from "../../components/cardсall/cardсall";
import None from "../../components/none/none";

function Trabalha() {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.section_1}>
        <h1 className={styles.text}>
          Вы живете в Порту или поблизости и хотите работать?
        </h1>
        <h2 className={styles.text}>Присоединяйтесь к нашей команде</h2>
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Как с нами связаться?</h2>
        <div className={styles.list}>
          {dataCall.map((item, index) => {
            return (
              <Cardсall
                name={item.name}
                actionType={item.actionType}
                icon={item.icon}
                iconw={item.iconw}
                key={index}
                link={item.link}
              ></Cardсall>
            );
          })}
        </div>
        <None hh={50}></None>
      </div>
    </div>
  );
}

export default Trabalha;
/*useEffect(() => {}, []);
  const [isactiv, setIsactiv] = useState(0);*/
