import styles from "./slideway.module.css";
import sly from "../../images/slide.png";

type TOrderypeprops = {
  nameone: string;
  linkone: string;
  nametwo: string;
  namethree: string;
};

function SlideWay() {
  const backgraundMain = {
    backgroundImage: `url(${sly})`,
  };
  return <div className={styles.main} style={backgraundMain}></div>;
}

export default SlideWay;
