import { AnimatePresence, motion } from "framer-motion";
import styles from "./app-header.module.css";
import dog from "../../images/logo_1.png";
import ww from "../../images/w.png";
import wh from "../../images/h.png";
import wi from "../../images/i.png";
import wt from "../../images/t.png";
import we from "../../images/e.png";
import ws from "../../images/s.png";
import wp from "../../images/p.png";
import wz from "../../images/z.png";
import { NavLink } from "react-router-dom";
import MenuLeft from "../menuleft/menuleft";

const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3, duration: 0.2 },
  }),
};

export function AppHeader() {
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <NavLink className={styles.preview} to={"/"}>
          <motion.img
            src={dog}
            alt="logo"
            animate={{ rotate: 360 }}
            transition={{
              duration: 2,
              repeat: 1,
              repeatDelay: 1,
              repeatType: "mirror",
              type: "tween",
            }}
          />
        </NavLink>
        <motion.div className={styles.title}>
          <motion.div
            className={styles.letters}
            initial="hidden"
            whileInView="visible"
          >
            <AnimatePresence>
              <motion.img
                className={styles.letter}
                src={ww}
                alt="w"
                custom={1}
                variants={textAnimation}
              />
            </AnimatePresence>
            <motion.img
              className={styles.letter}
              src={wh}
              alt="h"
              custom={2}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wi}
              alt="i"
              custom={3}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wt}
              alt="t"
              custom={4}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={we}
              alt="e"
              custom={5}
              variants={textAnimation}
            />
          </motion.div>

          <motion.div
            className={styles.letterstwo}
            initial="hidden"
            whileInView="visible"
          >
            <motion.img
              className={styles.letter}
              src={ws}
              alt="s"
              custom={6}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wp}
              alt="p"
              custom={7}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wi}
              alt="i"
              custom={8}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wt}
              alt="t"
              custom={9}
              variants={textAnimation}
            />
            <motion.img
              className={styles.letter}
              src={wz}
              alt="z"
              custom={10}
              variants={textAnimation}
            />
          </motion.div>
        </motion.div>
      </div>

      <div className={styles.menuM}>
        <MenuLeft></MenuLeft>
      </div>
    </div>
  );
}
export default AppHeader;
