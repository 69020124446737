import React, { VFC, useState } from "react";
import styles from "./cardclean.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

type Tprops = {
  name: string;
  list: string[];
  image: string;
  link: string;
};

const Cardclean = (props: Tprops) => {
  const list = props.list;
  return (
    <motion.div
      className={styles.main}
      whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
    >
      <img className={styles.image} src={props.image} alt={props.name} />
      <div className={styles.name}>{props.name}</div>
      {list.map((item, index) => {
        return (
          <div className={styles.item} key={index}>
            {item}
          </div>
        );
      })}
      <NavLink className={styles.button} to={props.link}>
        <motion.div whileHover={{ scale: 1.2 }}>Узнать больше</motion.div>
      </NavLink>
    </motion.div>
  );
};

export default Cardclean;
