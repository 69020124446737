import styles from "./company.module.css";
import { dataCompany } from "../../utils/data";
import { dataCall } from "../../utils/data";
import { listtext } from "../../utils/data";
import iconfon from "../../images/iconfon.jpg";
import Runstring from "../../components/runstring/runstring";
import Cardcompany from "../../components/cardcompany/cardcompany";
import Cardсall from "../../components/cardсall/cardсall";
import None from "../../components/none/none";

function Company() {
  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.section_1}>
        <h1 className={styles.text}>
          Мы компания домашнего сервиса и мы можем:
        </h1>
        <h2 className={styles.text}>Сделать вашу жизнь ярче</h2>
        <h3 className={styles.text}>
          Eсли не тратить драгоценное время на домашнюю рутину, можно...
        </h3>
        <div className={styles.strings}>
          <Runstring strings={listtext} delay={70}></Runstring>
        </div>
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Сделать вашу жизнь проще</h2>
        <h3 className={styles.text}>потому что у нас</h3>
        <div className={styles.list}>
          {dataCompany.map((item, index) => {
            return (
              <Cardcompany
                name={item.name}
                actionType={item.actionType}
                icon={item.icon}
                iconw={item.iconw}
                key={index}
              ></Cardcompany>
            );
          })}
        </div>
      </div>

      <div className={styles.section_1}>
        <h2 className={styles.text}>Как с нами связаться?</h2>
        <div className={styles.list}>
          {dataCall.map((item, index) => {
            return (
              <Cardсall
                name={item.name}
                actionType={item.actionType}
                icon={item.icon}
                iconw={item.iconw}
                key={index}
                link={item.link}
              ></Cardсall>
            );
          })}
        </div>
        <None hh={50}></None>
      </div>
    </div>
  );
}

export default Company;
/*useEffect(() => {}, []);
  const [isactiv, setIsactiv] = useState(0);*/
