import styles from "./main.module.css";
import Video from "../../components/video/video";
import Flat from "../../components/flat/flat";
import Cleaning from "../../components/cleaning/cleaning";
import { useResize } from "../../utils/hook";
import SlideWay from "../../components/sladeway/slideway";
import AddServ from "../../components/addserv/addserv";
import Wecan from "../../components/wecan/wecan";
import Contact from "../../components/contact/contact";
import None from "../../components/none/none";
import Reclama from "../../components/reclama/reclama";

function MainPage() {
  const wl = useResize().isScreenXl;

  return (
    <div className={styles.main}>
      {wl && (
        <div className={styles.examplecontainer}>
          <Video></Video>
        </div>
      )}
      {wl && <div className={styles.examplecontainer2}></div>}
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <div className={styles.containerflat}>
          <Flat></Flat>
        </div>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Cleaning></Cleaning>
        <None hh={100}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Wecan></Wecan>
        <None hh={100}></None>
      </div>

      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <AddServ></AddServ>
        <None hh={200}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Reclama></Reclama>
        <None hh={200}></None>
      </div>

      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Contact></Contact>
        <None hh={50}></None>
      </div>
    </div>
  );
}

export default MainPage;
