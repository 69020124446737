import styles from "./advice.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import dog from "../../images/slide01.png";
import dog2 from "../../images/slide02.png";
import dog3 from "../../images/slide03.png";

import dogs from "../../images/image01min.png";
import dogs2 from "../../images/image05min.png";
import dogs3 from "../../images/image07min.png";

import dot from "../../images/roundbw.png";
import dota from "../../images/roundbwa.png";

import iconfon from "../../images/iconfon.jpg";

import { texta } from "../../utils/text";
import { NavLink } from "react-router-dom";
import { useResize } from "../../utils/hook";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const backgraunddot = {
  backgroundImage: `url(${dot})`,
};
const backgraunddota = {
  backgroundImage: `url(${dota})`,
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const Advice = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const wl = useResize().isScreenSm;
  const [images, setImages] = useState<string[]>([dog, dog2, dog3]);
  let imagess = [dog, dog2, dog3];

  useEffect(() => {
    if (wl) {
      imagess = [dog, dog2, dog3];
      setImages(imagess);
    } else {
      imagess = [dogs, dogs2, dogs3];
      setImages(imagess);
    }
  }, [wl]);

  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const backgraundMain = {
    backgroundImage: `url(${iconfon})`,
  };

  return (
    <div className={styles.main} style={backgraundMain}>
      <div className={styles.imgblock}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={styles.image}
            key={page}
            style={{ backgroundImage: `url(${images[imageIndex]})` }}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            <div className={styles.box}>
              <h1 className={styles.tit}>{texta[imageIndex].name}</h1>
              <NavLink className={styles.link} to={`/advice/${imageIndex}`}>
                Читать...
              </NavLink>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className={styles.dotblock}>
          {images.map((item, index) => {
            if (index === imageIndex) {
              return (
                <div
                  className={styles.dot}
                  style={backgraunddot}
                  key={index}
                ></div>
              );
            } else {
              return (
                <div
                  className={styles.dot}
                  style={backgraunddota}
                  key={index}
                ></div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Advice;
