import styles from "./watch.module.css";
import { useState } from "react";
import dog from "../../images/round.png";
import { motion } from "framer-motion";
type TWatch = {
  func: React.Dispatch<React.SetStateAction<string>>;
  dura: number;
  data: string;
};

export const Watch = (props: TWatch) => {
  const setdata = props.func;
  const dura = props.dura;
  const [day, setday] = useState([
    { nomone: 12, actone: false },
    { nomone: 1, actone: false },
    { nomone: 2, actone: false },
    { nomone: 3, actone: false },
    { nomone: 4, actone: false },
    { nomone: 5, actone: false },
    { nomone: 6, actone: false },
    { nomone: 7, actone: false },
    { nomone: 8, actone: false },
    { nomone: 9, actone: false },
    { nomone: 10, actone: false },
    { nomone: 11, actone: false },
  ]);
  const [falsed, setFalsed] = useState(false);
  const [falsedtext, setFalsedtext] = useState("");

  const clock = [0, 1, 2, 3, 4, 5, 6];

  const onClick = (nom: number) => {
    let result: {
      nomone: number;
      actone: boolean;
    }[] = [];
    let fin = 0;
    const finish = 9 - dura;
    if (dura > 9) {
      setFalsed(true);
      setFalsedtext("Предстоит большая работа давайте начнем с утра");
      setTimeout(() => {
        setFalsed(falsed);
        setFalsedtext("");
      }, 2000);
      day.map((item) => {
        const rr = {
          nomone: item.nomone,
          actone: true,
        };
        result.push(rr);
      });
      setday(result);
      setdata(` с ${nom} до ${fin} ч.`);
    } else {
      if (nom > finish && nom < 9) {
        setFalsed(true);
        setFalsedtext(
          "Мы работаем до 9 вечера, уборка не может закончиться позднее"
        );
        setTimeout(() => {
          setFalsed(falsed);
          setFalsedtext("");
        }, 2000);
        day.map((item) => {
          const rr = {
            nomone: item.nomone,
            actone: false,
          };
          result.push(rr);
        });
      } else {
        day.map((item) => {
          const rr = {
            nomone: item.nomone,
            actone: false,
          };
          result.push(rr);
        });

        let i = 0;
        while (i < dura + 1) {
          if (nom + i > 11) {
            result[nom - 12 + i].actone = true;
            fin = nom - 12 + i;
          } else {
            result[nom + i].actone = true;
            fin = nom + i;
          }
          i += 1;
        }
        setday(result);
        setdata(` с ${nom} до ${fin} ч.`);
      }
    }
  };

  const onSeediv = (one: number) => {
    if (!day[one].actone) {
      return (
        <div className={styles.hour} onClick={() => onClick(one)}>
          {day[one].nomone}
        </div>
      );
    } else {
      return (
        <div
          className={`${styles.hour} ${styles.hourstart}`}
          onClick={() => onClick(one)}
        >
          {day[one].nomone}
        </div>
      );
    }
  };
  return (
    <div className={styles.main}>
      <motion.img
        src={dog}
        alt="logo"
        className={styles.image}
        animate={{ rotate: 360 }}
        transition={{
          duration: 2,
          repeat: 1,
          repeatDelay: 1,
          repeatType: "mirror",
          type: "tween",
        }}
      />
      <div className={styles.sec}>
        {clock.map((item, index) => {
          if (index === 0) {
            return (
              <div className={`${styles.seczero}`} key={index}>
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 1) {
            return (
              <div className={`${styles.secone}`} key={index}>
                {onSeediv(12 - index)}
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 2) {
            return (
              <div className={`${styles.sectwo}`} key={index}>
                {onSeediv(12 - index)}
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 3) {
            return (
              <div className={`${styles.sectree}`} key={index}>
                {onSeediv(12 - index)}
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 4) {
            return (
              <div className={`${styles.sectwo}`} key={index}>
                {onSeediv(12 - index)}
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 5) {
            return (
              <div className={`${styles.secfive}`} key={index}>
                {onSeediv(12 - index)}
                {onSeediv(index)}
              </div>
            );
          }
          if (index === 6) {
            return (
              <div className={`${styles.secs}`} key={index}>
                {onSeediv(index)}
              </div>
            );
          }
        })}
        {falsed && (
          <motion.div
            className={styles.falsed}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            {falsedtext}
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default Watch;
