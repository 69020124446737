import styles from "./app.module.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "../../pages/main/main";
import Coast from "../../pages/coast/coast";
import AppHeader from "../app-header/app-header";
import Regularclean from "../../pages/regularclean/regularclean";
import Afterrepairclean from "../../pages/afterrepairclean/afterrepairclean";
import Deepclean from "../../pages/deepclean/deepclean";
import Clean from "../../pages/clean/clean";
import AppBottom from "../app-bottom/app-bottom";
import Company from "../../pages/company/company";
import Order from "../../pages/order/order";
import Adviceone from "../adviceone/adviceone";
import AdvicePage from "../../pages/advicepage/advicepage";
import Trabalha from "../../pages/trabalha/trabalha";

function App() {
  useEffect(() => {
    document.title = "PClining";
  }, []);

  return (
    <div className={styles.app}>
      <div className={styles.header}>
        <AppHeader></AppHeader>
      </div>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/price" element={<Coast />} />
        <Route path="/company" element={<Company />} />
        <Route path="/order" element={<Order />} />
        <Route path="/clean" element={<Clean />} />
        <Route path="/baseclean" element={<Regularclean />} />
        <Route path="/deepclean" element={<Deepclean />} />
        <Route path="/afterrepairclean" element={<Afterrepairclean />} />
        <Route path="/advice/:name" element={<Adviceone />} />
        <Route path="/advice" element={<AdvicePage />} />
        <Route path="/work" element={<Trabalha />} />
      </Routes>
      <div className={styles.section_pod}>
        <AppBottom></AppBottom>
      </div>
    </div>
  );
}

export default App;
//<Route path="/profile" element={<OnlyAuth component={<Profile />} />} />
//<Route path="/reg" element={<Formcall />} />
